import React from 'react'

export const ContactoComponent = () => {
  return (
    <div className='page'>
        <h1 className='heading'>Contacto</h1>

            <form className='contact' action="mailto:michael_andre_525@hotmail.com">
                <input type='text' placeholder='Nombre'/>
                <input type='text' placeholder='Apellidos'/>
                <input type='text' placeholder='Email'/>
                <textarea placeholder='Motivo de contacto'/>
                <input type='submit' value="Enviar"/>

            </form>
              
        <p>Correo Electrónico: michael.cuadros.cc@gmail.com</p>
        <p>Número de Teléfono: 982960959</p>
        <p>Linkedin: https://www.linkedin.com/in/michael-cuadros-ccahuana/</p>
        <p>Además, para una comunicación más rápida y directa, también puedes escribirme a través de WhatsApp utilizando el número proporcionado.</p>
    </div>
  )
}
