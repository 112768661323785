import './App.css';
import { RutasComponent } from './Router/RutasComponent';

function App() {
  document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });

  return (
    <div className="layout">

        <RutasComponent/>
    </div>
  );
}

export default App;
