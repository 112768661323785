export const trabajos = [
  {
    id: "aprobandosinestudiar",
    nombre: "ASE",
    url: "https://www.aprobandosinestudiar.com",
    tecnologias:
      "JS, Mongoose, MongoDB, EXPRESS, REACT JS, NODE, JWT, Hostinger, Atlas, Render",
    categorias: "Backend, Fronted",
    descripcion:
      "Este proyecto es el resultado de mi deseo personal de brindar a nuestros compañeros una valiosa colección de prácticas calificadas de ciclos académicos previos, para facilitar su proceso de estudio. En el desarrollo de esta iniciativa, utilicé tecnologías como JavaScript (JS), Mongoose, Express, React JS, Node.js, y apliqué la seguridad con JSON Web Tokens (JWT). Para que este proyecto sea accesible en línea, lo alojé en Hostinger, y aproveché la eficiencia de MongoDB Atlas como mi base de datos. Además, implementé el despliegue del backend a través de la plataforma Render.",
  },
  {
    id: "antasol",
    nombre: "Antasol",
    url: "https://www.antasol.com",
    tecnologias: "Next.js, React, Tailwind, Vercel, GoDaddy",
    categorias: "Frontend, Hosting",
    descripcion:
      "Antasol es un emprendimiento enfocado en alquilar una casa ubicada en Lunahuaná, una zona turística en Perú. El sitio web está desarrollado con Next.js, React y Tailwind, se encuentra alojado en Vercel y el dominio está registrado en GoDaddy.",
  },
  {
    id: "michaelAduanas",
    nombre: "API Web Scraping Aduanas",
    url: "aduanas.michaelcuadros.com",
    tecnologias: "Web Scraping, ReactJS, Node.js, Express, MongoDB",
    categorias: "Web Scraping, Backend, Frontend",
    descripcion:
      "La API Web Scraping Aduanas proporciona acceso a datos de valores de distintas monedas mediante técnicas avanzadas de web scraping. Ofrece documentación detallada de los endpoints para facilitar su integración y uso por parte de desarrolladores y entusiastas de la automatización. Ideal para aplicaciones que requieren información actualizada de tasas de cambio aduaneras.",
  },

  {
    id: "englishtalesangular",
    nombre: "English Tales Web",
    url: "https://virtual-tales-english.vercel.app/login",
    tecnologias: "TypeScript, Angular, Postgres SQL, Node, JWT",
    categorias: "Backend, Fronted",
    descripcion:
      '"English Tales Virtual" es un proyecto creado como parte del curso universitario de Arquitectura de Aplicaciones Web. Este proyecto representa la aplicación práctica de los conocimientos adquiridos durante el curso, incluyendo el desarrollo de backend y frontend. La plataforma es una biblioteca virtual de audiolibros diseñada para ayudar a los usuarios a aprender inglés. Se han utilizado componentes de Angular Material para proporcionar una experiencia de usuario moderna y funcional. El proyecto se beneficia de una infraestructura en la nube, con la base de datos alojada en Amazon, el backend en Render y el frontend en Vercel. Esto proporciona una arquitectura escalable y robusta, aunque la velocidad de carga inicial puede ser un poco más lenta debido a los servicios de hosting gratuitos utilizados.',
  },

  /*     {
        'id':'talleres',
        'nombre':'Talleres de Confianza',
        'url':'https://frontend-talleres.vercel.app',
        'tecnologias':'JS, Mongoose, EXPRESS, REACT, NODE, JWT',
        'categorias':'Backend, Fronted',
        'descripcion':'"Explora nuestra plataforma con frontend en Vercel y backend en Render. Utilizamos React, JWT, Express, Node, MongoDB y Mongoose. Ten en cuenta que al depender de servicios gratuitos, podrías experimentar limitaciones ocasionales en el rendimiento. Aun así, estamos comprometidos a brindarte una experiencia excepcional en cada visita.'
    }, */
  /*     {
        'id':'mispelis',
        'nombre':'Michael Pelis',
        'url':'https://peliculas-michael-cuadros.vercel.app/',
        'tecnologias':' React, JS, html, css',
        'categorias':'Fronted',
        'descripcion':'"Michael Pelis" es un proyecto frontend, creado por amor al arte e inspirado en la estética y funcionalidad de Netflix. Desarrollado utilizando React, JavaScript, HTML y CSS, y alojado en Vercel, este proyecto se destaca por su ingeniosa utilización de LocalStorage para la gestión de datos.'
    }, */
];
